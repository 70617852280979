import React, { useState, useEffect } from 'react';
import AdminLayout from '../../components/admin/AdminLayout'
import UserForm from '../../components/UserForm';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';


export default function UserAdd() {
  return (
    <div style={{ margin: '2rem' }}>
      <AdminLayout titleName='จัดการผู้ใช้'/>
      <Grid container style={{ marginTop: '4rem', marginBottom: '2rem' }}>
        <Grid item xs={12}>
          <Typography variant='h4' style={{ fontWeight: 'bold' }}>เพิ่มผู้ใช้ใหม่</Typography>
        </Grid>
        <Grid item xs={12} >
          <UserForm mode='add' />
        </Grid>
      </Grid>
    </div>
  )
}