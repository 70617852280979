import * as React from 'react';
// import ListSubheader from '@mui/material/ListSubheader';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import StarIcon from '@mui/icons-material/Logout';
import GroupsIcon from '@mui/icons-material/Groups';
import NewsIcon from '@mui/icons-material/Newspaper';
import PersonIcon from '@mui/icons-material/Person';
import GridViewIcon from '@mui/icons-material/GridView';
import Link from '@mui/material/Link';
import { logout } from '../../utils/Auth'
import LocalStorage from '../../utils/LocalStorage';

export default function NestedList() {
  // console.log(menu_list);
  const handleLogout = () => {
    logout()
  }

  return (
    <>
      <List>
        {/* Org management */}
        <Link href="/org/list" color="inherit" underline="none">
          <ListItemButton >
            <ListItemIcon>
              <GroupsIcon />
            </ListItemIcon>
            <ListItemText primary="จัดการหน่วยงาน" />
          </ListItemButton>
        </Link>
        {/* User group management */}
        {/* <Link href="/usergroup/list" color="inherit" underline="none">
          <ListItemButton >
            <ListItemIcon>
              <ReceiptIcon />
            </ListItemIcon>
            <ListItemText primary="User Group Management" />
          </ListItemButton>
        </Link> */}
        {/* User management */}
        <Link href="/user/list" color="inherit" underline="none">
          <ListItemButton >
            <ListItemIcon>
              <PersonIcon />
            </ListItemIcon>
            <ListItemText primary="จัดการผู้ใช้" />
          </ListItemButton>
        </Link>
        {/* System management */}
        <Link href="/application/list" color="inherit" underline="none">
          <ListItemButton >
            <ListItemIcon>
              <GridViewIcon />
            </ListItemIcon>
            <ListItemText primary="จัดการแอปพลิเคชัน" />
          </ListItemButton>
        </Link>
        <Link href="/news/list" color="inherit" underline="none">
          <ListItemButton >
            <ListItemIcon>
              <NewsIcon />
            </ListItemIcon>
            <ListItemText primary="จัดการข่าวสาร" />
          </ListItemButton>
        </Link>
        {/* <Link href='/menu/list' color="inherit" underline='none'>
          <ListItemButton>
            <ListItemIcon>
              <ReceiptIcon />
            </ListItemIcon>
            <ListItemText primary="Menu Management"/>
          </ListItemButton>
        </Link> */}
        {/* <Link href='/permis/list' color="inherit" underline='none'>
          <ListItemButton>
            <ListItemIcon>
              <ReceiptIcon />
            </ListItemIcon>
            <ListItemText primary="Permission Management"/>
          </ListItemButton>
        </Link> */}
        {/* <Link href='/register' color="inherit" underline='none'>
          <ListItemButton>
            <ListItemIcon>
              <ReceiptIcon />
            </ListItemIcon>
            <ListItemText primary="User Registration"/>
          </ListItemButton>
        </Link> */}
        {/* {
          menu_list.map((data) => {
            return(
              <Link href={data.menu_uri} color="inherit" underline="none">
                <ListItemButton >
                  <ListItemIcon>
                    <ReceiptIcon />
                  </ListItemIcon>
                  <ListItemText primary={data.menu_name} />
                </ListItemButton>
              </Link> 
            )
          })
        } */}
      </List>

      <Link href="/auth/login" color="inherit" underline="none">
        <ListItemButton
          style={{ position: 'absolute', bottom: 10, width: '100%' }}
          onClick={() => handleLogout()}
        >
          <ListItemIcon>
            <StarIcon />
          </ListItemIcon>
          <ListItemText primary="ออกจากระบบ" />
        </ListItemButton>
      </Link>
    </>
  );
}
