import axios from 'axios';
import config from '../../config.json';
import LocalStorage from '../../utils/LocalStorage';

export const getMenuList = async (obj) => {
  let token = LocalStorage.getAccessToken();
  return axios.post(
    config.BASE_API + 'applications/menu/get', obj,
    {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token
      }
    }
  )
}

export const menuDetail = async (obj) => {
  let token = LocalStorage.getAccessToken();
  return axios.post(
    config.BASE_API + 'applications/menu/detail'
    , obj,
    {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token
      }
    }
  )
}

export const setActivate = async (obj) => {
  let token = LocalStorage.getToken();  
  let header = {
    'Content-Type': 'application/json',
      'Authorization' : 'Bearer '+token
  }
  return axios.patch(
    config.BASE_API + 'users',
    obj,
    {
      headers: header
    }
  )
}

export const addMenu = async (obj) => {
  let token = LocalStorage.getAccessToken();
  return axios.post(
    config.BASE_API + 'applications/menu/create'
    , obj,
    {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token
      }
    }
  )
}


export const updateMenu = async (obj) => {
  let token = LocalStorage.getAccessToken();
  return axios.post(
    config.BASE_API + 'applications/menu/update'
    , obj,
    {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token
      }
    }
  )
}


export const deleteUser = async (obj) => {
  let token = LocalStorage.getToken();
  return axios.post(
    config.BASE_API + 'applications/menu/delete',
    obj,
    {
      headers: {
        'Content-Type': 'application/json',
        'Authorization' : 'Bearer '+token
      }
    }
  )
}