import React, { useState, useEffect } from 'react';
import AdminLayout from '../../components/admin/AdminLayout';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import { DataGrid } from '@mui/x-data-grid';

import { callService, deleteService, getService } from '../../services/Service';

export default function NewsList() {
  const [news_list, setNewsList] = useState([])
  const [filter, setFilter] = useState('')
  const [page, setPage] = useState(0)
  const [pageSize, setPageSize] = useState(10) 
  const [totalRows, setTotalRows] = useState(0)
  const [selected_news_id, setSelectedNewsID] = useState(0)
  const [alert_header, setAlertHeader] = useState("")
  const [alert_body, setAlertBody] = useState("")
  const [alert_mode, setAlertMode] = useState("")
  const [open_alert, setOpenAlert] = useState(false)
  const [seleted_status ,setSelectedStatus] =  useState(null)

  const handleClose = () => {
    setOpenAlert(false);
    setSelectedNewsID(0);
  };

  const columns = [
    {
      field: 'index',
      headerName: 'ลำดับ',
      maxWidth: 50,
      flex: 1,
    },
    {
      field: 'title',
      headerName: 'หัวข้อข่าว',
      minWidth: 150,
      flex: 2,
    },
    {
      field: 'is_active',
      headerName: 'สถานะการเปิดใช้งาน',
      sortable: false,
      maxWidth: 250,
      flex: 1,
      disableClickEventBubbling: true,
      renderCell: (cellValues) => (
        <FormGroup>
          <FormControlLabel
            control={
              <Switch
                size="small"
                sx={{
                  '& .MuiSwitch-switchBase.Mui-checked': {
                    color: '#76BC43',
                    '&:hover': {
                      backgroundColor: 'rgba(118, 188, 67, 0.08)',
                    },
                  },
                  '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
                    backgroundColor: '#76BC43',
                  },
                }}
                checked={cellValues.row.is_active === 1}
                onClick={(event) => handleStatusSwitch(event, cellValues)}
              />
            }
            label=""
          />
        </FormGroup>
      ),
    },
    {
      field: "edit",
      headerName: "Edit",
      sortable: false,
      maxWidth: 100,
      flex: 1,
      disableClickEventBubbling: true,
      renderCell: (cellValues) => (
        <IconButton
          color="secondary"
          aria-label="edit news"
          sx={{ color: '#76BC43' }}
          onClick={() => handleEditClick(cellValues)}
        >
          <EditIcon />
        </IconButton>
      ),
    },
    {
      field: "delete",
      headerName: "Delete",
      sortable: false,
      maxWidth: 100,
      flex: 1,
      disableClickEventBubbling: true,
      renderCell: (cellValues) => (
        <IconButton
          color="secondary"
          aria-label="delete news"
          sx={{ color: '#76BC43' }}
          onClick={(event) => handleDeleteClick(event, cellValues)}
        >
          <DeleteIcon />
        </IconButton>
      ),
    }
  ];

  const handleEditClick = (cellValues) => {
    window.location.href = `/news/edit/${cellValues.row.id}`;
  };

  const handleDeleteClick = (event, cellValues) => {
    
    let news_id = cellValues.row.id;
    let app_name = cellValues.row.title;
    let header = 'ลบข้อมูล ข่าวสาร';
    let body = 'คุณต้องการลบช้อมูล ';
    body += 'ข่าวสาร ' + app_name;
    setAlertHeader(header);
    setAlertBody(body);
    setSelectedNewsID(news_id);
    setAlertMode('delete');
    setOpenAlert(true);
  };

  const fetchNewsList = async () => {
    try {
      const res = await getService(`news/get?page=${page}&pageSize=${pageSize}&filter=${filter}`);

      if (res.data && res.data.data) {
        const { data, total } = res.data.data;
        const temp_news_list = data.map((item, index) => ({
          id: item.id,
          index: index + 1 + page * pageSize,
          title: item.title,
          is_active: item.is_active,
        }));


        setNewsList(temp_news_list);
        setTotalRows(total);
      } else {
        setNewsList([]);
        setTotalRows(0);
      }
    } catch (error) {
      console.error(error.response || error.message);
      if (error.response && error.response.status === 401) {
        window.location.href = "/auth/login";
      }
    }
  };

  const handleStatusSwitch = async (event, cellValues) => {
    let news_id = cellValues.row.id
    let news_title = cellValues.row.title 
    let is_active = cellValues.row.is_active === 1 ;
    let header = ' ข่าวสาร';
    let body = 'คุณต้องการ ';
    header = is_active ? 'ปิดการใช้งาน' + header : 'เปิดการใช้งาน' + header;
    body = is_active ? body + ' ปิดการใช้งาน ' : body + ' เปิดการใช้งาน ';
    body += 'หัวข้อข่าว ' + news_title;
    setAlertHeader(header);
    setAlertBody(body);
    setSelectedNewsID(news_id);
    setSelectedStatus(is_active)
    setOpenAlert(true);
  }

  const handleStatusSwitch1 = async (event, cellValues) => {
    const newStatus = cellValues.row.is_active === 1 ? 0 : 1;
    try {
      const res = await callService('news/updateStatus', {
        news_id: cellValues.row.id,
        is_active: newStatus,
      });
      if (res.status === 200) {
        const updatedNewsList = news_list.map(newsItem =>
          newsItem.id === cellValues.row.id ? { ...newsItem, is_active: newStatus } : newsItem
        );
        setNewsList(updatedNewsList);
      }
    } catch (error) {
      console.error("Failed to update status:", error.response || error.message);
    }
  };

  const handleAddClick = () => {
    window.location.href = "/news/add";
  };

  const handleAlertButton = () => {
    if (alert_mode === 'delete') {
      let obj = { news_id : selected_news_id };
      deleteService('news/delete/' + selected_news_id, obj)
        .then((res) => {
          handleClose();
          fetchNewsList();
        })
        .catch((error) => {
          if (error.response && error.response.status === 401) {
            window.location.href = '/auth/login';
          }
        });
    } else {
      let obj = { news_id : selected_news_id, is_active: !seleted_status ? '1' : '0' };
      callService('news/updateStatus', obj)
        .then((res) => {
          handleClose();
          fetchNewsList();
        })
        .catch((error) => {
          if (error.response && error.response.status === 401) {
            window.location.href = '/auth/login';
          }
        });
    }
  };

  useEffect(() => {
    fetchNewsList();
  }, [page, pageSize, filter]);

  const handlePageChange = (newPage) => {
    setPage(newPage);
  };

  const handlePageSizeChange = (newPageSize) => {
    setPageSize(newPageSize);
    setPage(0); // Reset to first page
  };

  const handleFilterChange = (event) => {
    setFilter(event.target.value);
    setPage(0); // Reset to first page when filter changes
  };

  return (
    <div style={{ margin: '2rem' }}>
      <AdminLayout />
      <Grid container style={{ marginTop: '4rem', marginBottom: '2rem' }}>
        <Grid item xs>
          <Typography variant='h4' style={{ fontWeight: 'bold' }}>
            จัดการข่าวสาร
          </Typography>
        </Grid>
        <Grid item>
          <Button variant="contained"
            sx={{
              backgroundColor: '#76BC43',
              '&:hover': {
                backgroundColor: '#5e9e36',
              },
            }} onClick={handleAddClick}>
            เพิ่มข่าวสาร
          </Button>
        </Grid>
        <Grid item xs={12}>
          <TextField
            variant="outlined"
            placeholder="ค้นหา"
            sx={{ width: "50%" }}
            value={filter}
            onChange={handleFilterChange}
            style={{ marginBottom: '1rem' }}
          />
          <div style={{ height: 600, width: '100%', marginTop: '2rem' }}>
            <DataGrid
              style={{ backgroundColor: "#FFFFFF" }}
              rows={news_list}
              columns={columns}
              pagination
              pageSize={pageSize}
              rowsPerPageOptions={[5, 10, 20, 50]}
              rowCount={totalRows}
              paginationMode="server"
              onPageChange={(newPage) => handlePageChange(newPage)}
              onPageSizeChange={(newPageSize) => handlePageSizeChange(newPageSize)}
            />
          </div>
        </Grid>
      </Grid>
      <Dialog
        open={open_alert}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{alert_header}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {alert_body}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleAlertButton} variant="contained" color="success">
            ตกลง
          </Button>
          <Button onClick={handleClose} variant="contained" color="error">
            ยกเลิก
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
