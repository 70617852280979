import React, { useState, useEffect } from 'react';
import AdminLayout from '../../components/admin/AdminLayout'
import OrgForm from '../../components/OrgForm';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';


export default function OrgEdit() {
  return (
    <div style={{ margin: '2rem' }}>
      <AdminLayout titleName='จัดการหน่วยงาน'/>
      <Grid container style={{ marginTop: '4rem', marginBottom: '2rem' }}>
        <Grid item xs={12}>
          <Typography variant='h4' style={{ fontWeight: 'bold' }}>แก้ไขหน่วยงาน</Typography>
        </Grid>
        <Grid item xs={12} >
          <OrgForm mode='edit' />
        </Grid>
      </Grid>
    </div>
  )
}