import React, { useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Alert from '@mui/material/Alert';
import Grid from '@mui/material/Grid';
import Autocomplete from '@mui/material/Autocomplete';

import logo from '../../rice_logo.png'
import CustomPasswordField from '../../components/password-textfield';
import { callService, getService } from '../../services/Service'

export default function SignIn() {
    const [isError, setIsError] = useState({})
    const [orgList, setOrgList] = useState([])
    const [values, setValues] = useState({
        first_name: '',
        last_name: '',
        username: '',
        password: '',
        org: '',
    })

    const handleSubmit = async (event) => {
        event.preventDefault();

        const validateLength = (value, minLength) => value.length >= minLength;

        let errors = {};

        for (const key in values) {
            if (!values[key]) {
                errors[key] = true;
                errors.errorMessage = "กรุณากรอกข้อมูลให้ครบถ้วน";
            } else {
                errors[key] = false;
            }
        }

        if (!validateLength(values.password, 8)) {
            errors.password = true;
            if (!errors.errorMessage) {
                errors.errorMessage = "รหัสผ่านต้องมีความยาวอย่างน้อย 8 ตัวอักษร";
            }
        }

        setIsError(errors);

        const allErrors = Object.values(errors);
        const hasError = allErrors.includes(true);

        if (!hasError) {
            try {
                await callService('users/create', {
                    username: values.username,
                    password: values.password,
                    first_name: values.first_name,
                    last_name: values.last_name,
                });

                const getUsers = await callService('users');
                const userId = getUsers.data.data[getUsers.data.data.length - 1].user_id;

                const org = orgList.find(item => item.org_name === values.org);

                const object2 = {
                    org_id: org.org_id,
                    group_id: org.org_id,
                    user_id: userId,
                };

                await callService('organizations/users/add', object2);

                alert('ลงทะเบียนสำเร็จ');
                window.location.reload();
            } catch (error) {
                console.error(error.response || error);

                if (error.response && error.response.status === 401) {
                    window.location.href = "/auth/login";
                } else {
                    alert(error.response?.data?.response || "เกิดข้อผิดพลาด. ลงทะเบียนไม่สำเร็จ!");
                }
            }
        } else {
            return;
        }
    }

    const handleChange = (event) => {
        const { name, value } = event.target
        setValues((prevState) => ({
            ...prevState,
            [name]: value,
        }));
    }

    useEffect(() => {
        getService('organizations/get')
            .then((res) => {
                const list = res.data.data
                setOrgList(list)
            })
    }, [])

    return (
        <Container component="main" maxWidth="xs">
            <CssBaseline />
            <Box
                display="flex"
                flexDirection="column"
                alignItems="center"
                justifyContent="center"
                height="100vh"
            >
                <img style={{ width: '200px' }} src={logo} alt='' />

                <Typography align='center' component="h1" variant="h5" p={2}>
                    Rice Department User Management Register System
                    {/* ระบบลงทะเบียนการจัดการผู้ใช้บริการกรมการข้าว */}
                </Typography>
                <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                required
                                fullWidth
                                id="first-name"
                                name="first_name"
                                autoComplete="first_name"
                                label="ชื่อ"
                                onChange={handleChange}
                                error={isError.first_name}
                                helperText={isError.first_name && 'ชื่อไม่ถูกต้อง'}
                            />
                        </Grid>

                        <Grid item xs={12} sm={6}>
                            <TextField
                                required
                                fullWidth
                                id="last-name"
                                name="last_name"
                                autoComplete="last_name"
                                label="นามสกุล"
                                onChange={handleChange}
                                error={isError.last_name}
                                helperText={isError.last_name && 'นามสกุลไม่ถูกต้อง'}
                            />
                        </Grid>
                        <Grid item xs={12} sm={12}>
                            <TextField
                                required
                                fullWidth
                                id="username"
                                name="username"
                                autoComplete="username"
                                label="ชื่อผู้ใช้"
                                onChange={handleChange}
                                error={isError.username}
                                helperText={isError.username && 'ชื่อผู้ใช้ไม่ถูกต้อง'}
                            />
                        </Grid>
                        <Grid item xs={12} sm={12}>
                            <CustomPasswordField
                                margin={"none"}
                                size={"normal"}
                                name={"password"}
                                showLabel={true}
                                lable={"รหัสผ่าน"}
                                placeholder={""}
                                handleChange={handleChange}
                                error={isError.password}
                                helperText={isError.password && 'รหัสผ่านไม่ถูกต้อง'}
                            />
                        </Grid>
                        <Grid item xs={12} sm={12}>
                            <Autocomplete
                                fullWidth
                                id="org"
                                options={orgList.map(org => org.org_name)}
                                onChange={(event, newValue) => handleChange({ target: { name: 'org', value: newValue } })}
                                renderInput={params => (
                                    <TextField
                                        {...params}
                                        label="หน่อยงาน"
                                        required
                                        error={isError.org}
                                        helperText={isError.org && 'หน่อยงานไม่ถูกต้อง'}
                                    />
                                )}
                            />
                        </Grid>
                    </Grid>

                    {
                        isError?.errorMessage && (
                            <Alert severity="error" sx={{ mt: '1rem' }}>
                                {isError.errorMessage}
                            </Alert>
                        )
                    }

                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        sx={{ mt: 3, mb: 2 }}
                    >
                        ลงทะเบียน
                    </Button>
                </Box>
            </Box>
        </Container>
    );
}