import React, { useState, useEffect } from 'react';
import AdminLayout from '../../components/admin/AdminLayout';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { DataGrid } from '@mui/x-data-grid';
import TextField from '@mui/material/TextField';
import { callService, deleteService, getService } from '../../services/Service';

export default function UserList() {
  const [userList, setUserList] = useState([]);
  const [selectedUserID, setSelectedUserID] = useState(0);
  const [selectedStatus, setSelectedStatus] = useState(false);
  const [alertHeader, setAlertHeader] = useState("");
  const [alertBody, setAlertBody] = useState("");
  const [alertMode, setAlertMode] = useState("");
  const [openAlert, setOpenAlert] = useState(false);
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [rowCount, setRowCount] = useState(0);
  const [filter, setFilter] = useState("");

  const handleClose = () => {
    setOpenAlert(false);
    setSelectedUserID(0);
    setSelectedStatus(false);
  };

  const columns = [
    {
      field: 'index',
      headerName: 'ลำดับ',
      maxWidth: 100,
      flex: 1,
    },
    {
      field: 'user_name',
      headerName: 'ชื่อบัญชีผู้ใช้',
      minWidth: 250,
      flex: 1,
    },
    {
      field: 'name',
      headerName: 'ชื่อ-นามสกุล',
      minWidth: 250,
      flex: 1,
    },
    {
      field: "edit",
      headerName: "แก้ไข",
      sortable: false,
      maxWidth: 100,
      flex: 1,
      disableClickEventBubbling: true,
      renderCell: (cellValues) => (
        <IconButton
          color="secondary"
          aria-label="edit"
          sx={{ color: '#76BC43' }}
          onClick={(event) => handleEditClick(event, cellValues)}
        >
          <EditIcon />
        </IconButton>
      )
    },
    {
      field: "delete",
      headerName: "ลบ",
      sortable: false,
      maxWidth: 100,
      flex: 1,
      disableClickEventBubbling: true,
      renderCell: (cellValues) => (
        <IconButton
          color="secondary"
          sx={{ color: '#76BC43' }}
          aria-label="delete"
          onClick={(event) => handleDeleteClick(event, cellValues)}
        >
          <DeleteIcon />
        </IconButton>
      )
    }
  ];

  const handleEditClick = (event, cellValues) => {
    event.preventDefault();
    window.location.href = "/user/edit/" + cellValues.row.id;
  };

  const handleDeleteClick = (event, cellValues) => {
    event.preventDefault();
    let user_id = cellValues.row.id;
    let user_name = cellValues.row.user_name;
    let header = "ลบข้อมูล ผู้ใช้งาน";
    let body = "คุณต้องการลบข้อมูลผู้ใช้งาน " + user_name;
    setAlertHeader(header);
    setAlertBody(body);
    setSelectedUserID(user_id);
    setAlertMode("delete");
    setOpenAlert(true);
  };

  const createUserList = () => {
    getService(`users?page=${page}&pageSize=${pageSize}&filter=${filter}`)
      .then(res => {
        let { data, total } = res.data.data;

        let temp_user_list = data.map((item, index) => ({
          id: item.user_id,
          index: page * pageSize + index + 1,
          user_name: item.username,
          name: item.full_name,
        }));
        setUserList(temp_user_list);
        setRowCount(total); // assuming the API returns the total count of records
      })
      .catch(error => {
        if (error.response.status === 401) {
          window.location.href = "/auth/login";
        }
      });
  };

  const handleAddClick = (event) => {
    event.preventDefault();
    window.location.href = "/user/add";
  };

  const handleAlertButton = () => {
    if (alertMode === "delete") {
      deleteService("users/delete/" + selectedUserID)
        .then(res => {
          handleClose();
          createUserList();
        })
        .catch(error => {
          if (error.response) {
            if (error.response.status === 401) {
              window.location.href = "/auth/login";
            }
          }
        });
    }
  };

  useEffect(() => {
    createUserList();
  }, [page, pageSize, filter]); // Fetch data on page, pageSize, or filter change

  const handleFilterKeyPress = (event) => {
    console.log(event.key)
    if (event.key === 'Enter') {
      createUserList();
    }
  };

  return (
    <div style={{ margin: '2rem' }}>
      <AdminLayout titleName='จัดการผู้ใช้' />
      <Grid container style={{ marginTop: '4rem', marginBottom: '2rem' }}>
        <Grid item xs>
          <Typography variant='h4' style={{ fontWeight: 'bold' }}>จัดการผู้ใช้</Typography>
        </Grid>
        <Grid item>
          <Button
            variant="contained"
            sx={{
              backgroundColor: '#76BC43',
              '&:hover': {
                backgroundColor: '#5e9e36',
              },
            }}
            onClick={handleAddClick}
          >
            เพิ่มผู้ใช้
          </Button>
        </Grid>
        <Grid item xs={12} style={{ marginBottom: '1rem', marginTop: '2rem' }}>
          <TextField
            label="ค้นหา"
            variant="outlined"
            sx={{width:'50vw'}}
            value={filter}
            onChange={(e) => setFilter(e.target.value)}
            onKeyPress={handleFilterKeyPress}
          />
        </Grid>
        <Grid item xs={12}>
          <div style={{ height: 600, width: '100%', marginTop: '2rem' }}>
            <DataGrid
              style={{ backgroundColor: "#FFFFFF" }}
              rows={userList}
              columns={columns}
              pageSize={pageSize}
              onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
              paginationMode="server"
              rowCount={rowCount}
              onPageChange={(newPage) => setPage(newPage)}
              rowsPerPageOptions={[5, 10, 20]}
              pagination
            />
          </div>
        </Grid>
      </Grid>

      <Dialog
        open={openAlert}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {alertHeader}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {alertBody}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleAlertButton} variant="contained" color="success">
            ตกลง
          </Button>
          <Button onClick={handleClose} variant="contained" color="error">
            ยกเลิก
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
