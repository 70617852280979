import React, { useState, useEffect, useCallback } from 'react';
import AdminLayout from '../../components/admin/AdminLayout';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { DataGrid } from '@mui/x-data-grid';
import TextField from '@mui/material/TextField';
import debounce from 'lodash.debounce';

import { callService, deleteService, getService } from '../../services/Service';

export default function UserList() {
  const [appList, setAppList] = useState([]);
  const [selectedAppID, setSelectedAppID] = useState(0);
  const [selectedStatus, setSelectedStatus] = useState(false);
  const [alertHeader, setAlertHeader] = useState('');
  const [alertBody, setAlertBody] = useState('');
  const [alertMode, setAlertMode] = useState('');
  const [openAlert, setOpenAlert] = useState(false);
  const [pageSize, setPageSize] = useState(10);
  const [page, setPage] = useState(0);
  const [rowCount, setRowCount] = useState(0);
  const [filter, setFilter] = useState('');

  const handleClose = () => {
    setOpenAlert(false);
    setSelectedAppID(0);
    setSelectedStatus(false);
  };

  const columns = [
    { field: 'index', headerName: 'ลำดับ', maxWidth: 100, flex: 1 },
    { field: 'id', headerName: 'Application Id', minWidth: 250, flex: 1 },
    { field: 'app_name', headerName: 'ชื่อแอปพลิเคชัน', minWidth: 250, flex: 1 },
    {
      field: 'status',
      headerName: 'สถานะการเปิดใช้งาน',
      sortable: false,
      maxWidth: 150,
      flex: 1,
      disableClickEventBubbling: true,
      renderCell: (cellValues) => (
        <FormGroup>
          <FormControlLabel
            control={
              <Switch
                size="small"
                sx={{
                  '& .MuiSwitch-switchBase.Mui-checked': {
                    color: '#76BC43',
                    '&:hover': {
                      backgroundColor: 'rgba(118, 188, 67, 0.08)',
                    },
                  },
                  '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
                    backgroundColor: '#76BC43',
                  },
                }}
                checked={cellValues.row.status === 'A'}
                onClick={(event) => handleStatusSwitch(event, cellValues)}
              />
            }
            label=""
          />
        </FormGroup>
      ),
    },
    {
      field: 'edit',
      headerName: 'แก้ไข',
      sortable: false,
      maxWidth: 100,
      flex: 1,
      disableClickEventBubbling: true,
      renderCell: (cellValues) => (
        <IconButton
          aria-label="edit"
          sx={{ color: '#76BC43' }}
          onClick={(event) => handleEditClick(event, cellValues)}
        >
          <EditIcon />
        </IconButton>
      ),
    },
    {
      field: 'delete',
      headerName: 'ลบ',
      sortable: false,
      width: 100,
      disableClickEventBubbling: true,
      renderCell: (cellValues) => (
        <IconButton
          aria-label="delete"
          sx={{ color: '#76BC43' }}
          onClick={(event) => handleDeleteClick(event, cellValues)}
        >
          <DeleteIcon />
        </IconButton>
      ),
    },
  ];

  const handleStatusSwitch = (event, cellValues) => {
    let app_id = cellValues.row.id;
    let app_name = cellValues.row.app_name;
    let status = cellValues.row.status === 'A';
    let header = ' แอปพลิเคชัน';
    let body = 'คุณต้องการ ';
    header = status ? 'ปิดการใช้งาน' + header : 'เปิดการใช้งาน' + header;
    body = status ? body + ' ปิดการใช้งาน ' : body + ' เปิดการใช้งาน ';
    body += 'แอปพลิเคชัน ' + app_name;
    setAlertHeader(header);
    setAlertBody(body);
    setSelectedAppID(app_id);
    setSelectedStatus(status);
    setAlertMode('status');
    setOpenAlert(true);
  };

  const handleEditClick = (event, cellValues) => {
    event.preventDefault();
    window.location.href = '/application/edit/' + cellValues.row.id;
  };

  const handleDeleteClick = (event, cellValues) => {
    event.preventDefault();
    let app_id = cellValues.row.id;
    let app_name = cellValues.row.app_name;
    let header = 'ลบข้อมูล แอปพลิเคชัน';
    let body = 'คุณต้องการลบช้อมูล ';
    body += 'แอปพลิเคชัน ' + app_name;
    setAlertHeader(header);
    setAlertBody(body);
    setSelectedAppID(app_id);
    setAlertMode('delete');
    setOpenAlert(true);
  };

  const createAppList = (page, pageSize, filter) => {
    const params = { page, pageSize, filter };
    getService(`applications?page=${page}&pageSize=${pageSize}&filter=${filter}`)
      .then((res) => {
        let { data, total } = res.data.data;
        let temp_app_list = data.map((item, index) => ({
          id: item.app_id,
          index: page * pageSize + index + 1,
          app_name: item.app_name,
          status: item.app_status,
        }));
        setAppList(temp_app_list);
        setRowCount(total); // assuming the API returns the total count of records
      })
      .catch((error) => {
        if (error.response.status === 401) {
          window.location.href = '/auth/login';
        }
      });
  };

  const handleAddClick = (event) => {
    event.preventDefault();
    window.location.href = '/application/add';
  };

  const handleAlertButton = () => {
    if (alertMode === 'delete') {
      deleteService('applications/delete/' + selectedAppID)
        .then((res) => {
          handleClose();
          createAppList(page, pageSize, filter);
        })
        .catch((error) => {
          if (error.response && error.response.status === 401) {
            window.location.href = '/auth/login';
          }
        });
    } else {
      let obj = { app_id: selectedAppID, status: !selectedStatus ? 'A' : 'I' };
      callService('applications/set_status', obj)
        .then((res) => {
          handleClose();
          createAppList(page, pageSize, filter);
        })
        .catch((error) => {
          if (error.response && error.response.status === 401) {
            window.location.href = '/auth/login';
          }
        });
    }
  };

  useEffect(() => {
    createAppList(page, pageSize, filter);
  }, [page, pageSize, filter]);

  const debouncedSearch = useCallback(
    debounce((value) => {
      setFilter(value);
    }, 300),
    []
  );

  const handleFilterChange = (event) => {
    debouncedSearch(event.target.value);
  };

  return (
    <div style={{ margin: '2rem' }}>
      <AdminLayout titleName="จัดการแอปพลิเคชัน" />
      <Grid container style={{ marginTop: '4rem', marginBottom: '2rem' }}>
        <Grid item xs>
          <Typography variant="h4" style={{ fontWeight: 'bold' }}>
            จัดการแอปพลิเคชัน
          </Typography>
        </Grid>
        <Grid item>
          <Button
            variant="contained"
            onClick={handleAddClick}
            sx={{
              backgroundColor: '#76BC43',
              '&:hover': {
                backgroundColor: '#5e9e36',
              },
            }}
          >
            เพิ่มแอปพลิเคชัน
          </Button>
        </Grid>
        <Grid item xs={12} style={{ marginBottom: '1rem', marginTop: '2rem' }}>
          <TextField
            label="ค้นหา"
            variant="outlined"
            sx={{ width: '50vw' }}
            onChange={handleFilterChange}
          />
        </Grid>
        <Grid item xs={12}>
          <div style={{ height: 600, width: '100%', marginTop: '2rem' }}>
            <DataGrid
              style={{ backgroundColor: '#FFFFFF' }}
              rows={appList}
              columns={columns}
              pageSize={pageSize}
              onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
              paginationMode="server"
              rowCount={rowCount}
              onPageChange={(newPage) => setPage(newPage)}
              rowsPerPageOptions={[5, 10, 20]}
              pagination
            />
          </div>
        </Grid>
      </Grid>
      <Dialog
        open={openAlert}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{alertHeader}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {alertBody}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleAlertButton} variant="contained" color="success">
            ตกลง
          </Button>
          <Button onClick={handleClose} variant="contained" color="error">
            ยกเลิก
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
