import React, { useState, useEffect, useCallback } from 'react';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import { Dialog, DialogTitle, DialogContent, DialogActions, IconButton, Typography, Switch, FormGroup, FormControlLabel } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import { DataGrid } from '@mui/x-data-grid';
import DeleteIcon from '@mui/icons-material/Delete';
import { useLocation } from 'react-router-dom';
import JoditEditor from "jodit-react";
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { callService, getService, updateService, userDetail } from '../services/Service';
import dayjs from 'dayjs';
import {
    uploadPicture
  } from '../services/admin/Upload'

const NewsForm = ({ mode }) => {
    const location = useLocation();
    const [buttonTitle, setButtonTitle] = useState("");
    const [newsDetails, setNewsDetails] = useState('');
    const [isForAll, setIsForAll] = useState(1);
    const [image, setImage] = useState('');
    const [data, setData] = useState({});
    const [isBusy, setBusy] = useState(mode === "add" ? false : true);
    const [organizations, setOrganizations] = useState([]);
    const [users, setUsers] = useState([]);
    const [listOrganizations, setListOrganizations] = useState([]);
    const [listUsers, setListUsers] = useState([]);
    const [openOrganizationDialog, setOpenOrganizationDialog] = useState(false);
    const [openUserDialog, setOpenUserDialog] = useState(false);
    const [inputOrganization, setInputOrganization] = useState(null);
    const [inputUser, setInputUser] = useState(null);
    const [dateTime, setDateTime] = useState(dayjs());

    useEffect(() => {
        if (mode === "edit") {
            loadNewsDetails();
            setButtonTitle("แก้ไขข่าวสาร");
        } else {
            setButtonTitle("เพิ่มข่าวสาร");
        }
        loadOrganizationOptions();
        loadUserOptions();
    }, [mode]);

    const loadNewsDetails = () => {
        let newsId = location.pathname.split('/')[3];
        userDetail('news/detail/' + newsId )
            .then(res => {
                let { data } = res.data.data;
                console.log(data);
                setData(data);
                if (data.news_date) {
                    setDateTime(dayjs(data.news_date));
                } else {
                    setDateTime(dayjs());
                }
                setImage(data.image)
                setIsForAll(data.is_for_all);
                setNewsDetails(data.detail);
                setOrganizations(data.organizations || []);
                setUsers(data.users || []);
                setBusy(false);
            });
    };

    const loadOrganizationOptions = () => {
        getService('organizations/get', {})
            .then(res => {
                let { data } = res.data.data;
                let list = data.map((item, index) => ({
                    index: index + 1,
                    id: item.org_id,
                    name: item.org_name,
                }));
                setListOrganizations(list);
            })
            .catch(error => console.error(error));
    };

    const loadUserOptions = () => {
        getService('users', {})
            .then(res => {
                let { data } = res.data.data;
                let list = data.map((item, index) => ({
                    index: index + 1,
                    id: item.user_id,
                    username: item.username
                }));
                setListUsers(list);
            })
            .catch(error => console.error(error));
    };

    const handleAddOrganization = () => {
        if (inputOrganization) {
            setOrganizations([...organizations, inputOrganization]);
            setOpenOrganizationDialog(false);
        }
    };

    const handleAddUser = () => {
        if (inputUser) {
            setUsers([...users, inputUser]);
            setOpenUserDialog(false);
        }
    };

    const handleRemoveOrganization = (index) => {
        const updatedOrganizations = [...organizations];
        updatedOrganizations.splice(index, 1);
        setOrganizations(updatedOrganizations);
    };

    const handleRemoveUser = (index) => {
        const updatedUsers = [...users];
        updatedUsers.splice(index, 1);
        setUsers(updatedUsers);
    };

    const handleSaveButton = () => {
        if (data.title === "" || !data.title) {
            alert("คุณยังไม่ระบุชื่อข่าวสาร \n กรุณาเพิ่มชื่อข่าวสาร");
        } else if (!newsDetails || newsDetails === "") {
            alert("คุณยังไม่ระบุรายละเอียดข่าวสาร \n กรุณาเพิ่มรายละเอียดข่าวสาร");
        } else {
            const newsObject = {
                title: data.title,
                summary: data.summary,
                image: image,
                is_for_all: isForAll,
                detail: newsDetails,
                organizations: organizations,
                users: users,
                news_date: dateTime.format('YYYY-MM-DD HH:mm:ss')
            };

            if (mode === "add") {
                callService('news/create', newsObject)
                    .then(res => {
                        alert("เพิ่มข่าวสารสำเร็จ");
                        window.location.href = "/news/list";
                    })
                    .catch(error => {
                        console.error(error.response);
                        if (error.response && error.response.status === 401) {
                            window.location.href = "/auth/login";
                        }
                    });
            } else {
                let newsId = location.pathname.split('/')[3];
                updateService('news/update/' + newsId, newsObject)
                    .then(res => {
                        alert("แก้ไขข่าวสารสำเร็จ");
                        window.location.href = "/news/list";
                    })
                    .catch(error => {
                        console.error(error.response);
                        if (error.response && error.response.status === 401) {
                            window.location.href = "/auth/login";
                        }
                    });
            }
        }
    };

    const onFileImageChange = (event) => {
        if (event.target.files[0] !== undefined) {
          // console.log(event.target.files[0].name);
          let file_name = event.target.files[0].name
          const formData = new FormData();
          formData.append(
            "file",
            event.target.files[0],
            event.target.files[0].name
          );
          uploadPicture(formData).then(res => {
            setImage(res.data.path)
          }).catch(err => {
            console.log(err);
          })
        }
    }

    const handleIsForAllSwitch = useCallback(() => {
        setIsForAll(isForAll === 1 ? 0 : 1)
    }, [isForAll])

    if (isBusy) {
        return <div>Loading...</div>;
    } else {
        return (
            <>
                <Card style={{ margin: '1.5rem 15%' }}>
                    <CardContent>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <TextField
                                    fullWidth
                                    id="outlined-title"
                                    label="ชื่อข่าวสาร"
                                    variant="outlined"
                                    value={data.title || ''}
                                    onChange={event => setData({ ...data, title: event.target.value })}
                                />
                            </Grid>
                            <Grid item xs={12} lg={4}>
                                {
                                !!image &&
                                    <img
                                        alt="รูป Banner"
                                        src={image}
                                        style={{ height: 'auto', width: '100%' }}
                                    />
                                }
                                <TextField
                                fullWidth
                                id="outlined-basic"
                                label="รูป Banner"
                                // value={news_thumbnail}
                                onChange={event => onFileImageChange(event)}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                sx={{
                                    backgroundColor: "#FFFFFF"
                                }}
                                type="file"
                                inputProps={{ accept: 'image/*' }}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Typography variant='body1' sx={{ pb: 0 }}>
                                    รายละเอียด
                                </Typography>
                                <JoditEditor
                                    value={newsDetails}
                                    config={{
                                        readonly: false,
                                        height: 400,
                                    }}
                                    tabIndex={1}
                                    onBlur={newContent => setNewsDetails(newContent)}
                                />
                            </Grid>
                            
                            <Grid item xs={12}>
                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <DateTimePicker
                                        label="วันที่เวลาแจ้งเตือน"
                                        value={dateTime}
                                        onChange={newValue => setDateTime(newValue)}
                                        renderInput={(params) => <TextField {...params} fullWidth />}
                                    />
                                </LocalizationProvider>
                            </Grid>

                            <Grid item xs={12}>
                                <div style={{ marginLeft: 10, marginTop: 10 }}>
                                    <FormControlLabel
                                        control={
                                        <Switch
                                            size="small"
                                            checked={!!isForAll}
                                            onClick={(event) => {
                                                handleIsForAllSwitch();
                                            }}
                                        />
                                        }
                                        label="แจ้งเตือนข่าวสารให้ทุกคน"
                                    />
                                </div>
                            </Grid>
                        </Grid>
                    </CardContent>
                    <CardActions>
                        <Grid container spacing={2} direction="row" alignItems="center">
                            <Grid item xs>
                                <p style={{ marginLeft: 10 }}>แก้ไขล่าสุด: {data.updated_date}</p>
                            </Grid>
                            <Grid item>
                                <Button
                                    variant="contained"
                                    color="success"
                                    onClick={handleSaveButton}
                                >
                                    {buttonTitle}
                                </Button>
                            </Grid>
                            <Grid item>
                                <Button
                                    variant="contained"
                                    color="error"
                                    onClick={() => window.location.href = "/news/list"}
                                >
                                    ย้อนกลับ
                                </Button>
                            </Grid>
                        </Grid>
                    </CardActions>
                </Card>

                { !isForAll &&
                    <>
                        <Card style={{ margin: '1.5rem 15%' }}>
                            <CardContent>
                                <Grid container spacing={2} alignItems="center">
                                    <Grid item>
                                        <Button
                                            variant="contained"
                                            sx={{
                                                backgroundColor: '#76BC43',
                                                '&:hover': {
                                                    backgroundColor: '#5e9e36',
                                                },
                                            }}
                                            onClick={() => setOpenOrganizationDialog(true)}
                                        >
                                            เพิ่มหน่วยงาน
                                        </Button>
                                    </Grid>
                                </Grid>
                                <div style={{ height: 300, marginTop: '2rem' }}>
                                    <DataGrid
                                        columns={[
                                            { field: 'id', headerName: 'ID', width: 100 },
                                            { field: 'name', headerName: 'หน่วยงาน', flex: 1 },
                                            {
                                                field: 'actions',
                                                headerName: 'ลบ',
                                                width: 100,
                                                renderCell: (params) => (
                                                    <IconButton
                                                        color="secondary"
                                                        onClick={() => handleRemoveOrganization(params.row.index)}
                                                    >
                                                        <DeleteIcon />
                                                    </IconButton>
                                                )
                                            }
                                        ]}
                                        rows={organizations.map((department, index) => ({
                                            id: department.id,
                                            name: department.name,
                                            index
                                        }))}
                                        pageSize={5}
                                    />
                                </div>
                            </CardContent>
                        </Card>

                        <Card style={{ margin: '1.5rem 15%' }}>
                            <CardContent>
                                <Grid container spacing={2} alignItems="center">
                                    <Grid item>
                                        <Button
                                            variant="contained"
                                            sx={{
                                                backgroundColor: '#76BC43',
                                                '&:hover': {
                                                    backgroundColor: '#5e9e36',
                                                },
                                            }}
                                            onClick={() => setOpenUserDialog(true)}
                                        >
                                            เพิ่มผู้ใช้
                                        </Button>
                                    </Grid>
                                </Grid>
                                <div style={{ height: 300, marginTop: '2rem' }}>
                                    <DataGrid
                                        columns={[
                                            { field: 'id', headerName: 'ID', width: 100 },
                                            { field: 'username', headerName: 'ชื่อบัญชีผู้ใช้', flex: 1 },
                                            {
                                                field: 'actions',
                                                headerName: 'ลบ',
                                                width: 100,
                                                renderCell: (params) => (
                                                    <IconButton
                                                        color="secondary"
                                                        onClick={() => handleRemoveUser(params.row.index)}
                                                    >
                                                        <DeleteIcon />
                                                    </IconButton>
                                                )
                                            }
                                        ]}
                                        rows={users.map((user, index) => ({
                                            id: user.id,
                                            username: user.username,
                                            index
                                        }))}
                                        pageSize={5}
                                    />
                                </div>
                            </CardContent>
                        </Card>
                    </>
                }

                {/* Dialog for Adding Organizations */}
                <Dialog open={openOrganizationDialog} onClose={() => setOpenOrganizationDialog(false)} fullWidth>
                    <DialogTitle>เลือกหน่วยงาน</DialogTitle>
                    <DialogContent>
                        <Autocomplete
                            options={listOrganizations}
                            getOptionLabel={(option) => option.name}
                            renderInput={(params) => (
                                <TextField {...params} label="เลือกหน่วยงาน" variant="outlined" fullWidth />
                            )}
                            onChange={(event, newInputValue) => {
                                setInputOrganization(newInputValue);
                            }}
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button
                            variant='contained'
                            sx={{ color: 'white', backgroundColor: 'green', fontSize: 20 }}
                            onClick={handleAddOrganization}>ตกลง</Button>
                        <Button
                            variant='contained'
                            sx={{
                                color: 'white',
                                backgroundColor: 'red',
                                fontSize: 20
                            }}
                            onClick={() => setOpenOrganizationDialog(false)}>
                            ยกเลิก
                        </Button>
                    </DialogActions>
                </Dialog>

                {/* Dialog for Adding Users */}
                <Dialog open={openUserDialog} onClose={() => { setOpenUserDialog(false) }} fullWidth>
                    <DialogTitle>เลือกผู้ใช้</DialogTitle>
                    <DialogContent>
                        <Autocomplete
                            options={listUsers}
                            getOptionLabel={(option) => option.username}
                            renderInput={(params) => (
                                <TextField {...params} label="เลือกผู้ใช้" variant="outlined" fullWidth />
                            )}
                            onChange={(event, newInputValue) => {
                                setInputUser(newInputValue);
                            }}
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button
                            variant='contained'
                            sx={{ color: 'white', backgroundColor: 'green', fontSize: 20 }}
                            onClick={handleAddUser}>ตกลง</Button>
                        <Button
                            variant='contained'
                            sx={{
                                color: 'white',
                                backgroundColor: 'red',
                                fontSize: 20
                            }}
                            onClick={() => { setOpenUserDialog(false) }}>
                            ยกเลิก
                        </Button>
                    </DialogActions>
                </Dialog>
            </>
        );
    }
};

export default NewsForm;
