/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import { DataGrid } from '@mui/x-data-grid';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle'
import MenuItem from '@mui/material/MenuItem';

import {
  useLocation
} from 'react-router-dom';

import {
  callOrg,
  editOrg,
  orgDetail
} from '../services/Org'
import Select from '@mui/material/Select';
import {
  callService,
  getService
} from '../services/Service'

const OrgForm = ({ mode }) => {
  const location = useLocation();
  const [button_title, setButtonTitle] = useState("")
  const [app_list, setAppList] = useState([])
  const [data, setData] = useState({
    org_name: "",
    org_code: "",
  })

  const [isBusy, setBusy] = useState(mode === "add" ? false : true)
  const [alert_header, setAlertHeader] = useState("")
  const [alert_body, setAlertBody] = useState("")
  const [alert_mode, setAlertMode] = useState("")
  const [open_alert, setOpenAlert] = useState(false);
  const [selected_app_list, setSelectedAppList] = useState(0)
  const [all_app_list, setAllAppList] = useState([])
  const [selected_app_id, setSelectedAppID] = useState(0)

  // const [isBusy,setBusy] = useState('')

  const columns = [
    {
      field: "index",
      headerName: "ลำดับ",
      maxWidth: 50,
      flex: 1
    },
    {
      field: "id",
      headerName: "Application Id",
      minWidth: 50,
      flex: 1
    },
    {
      field: "app_name",
      headerName: "ชื่อแอพพลิเคชั่น",
      minWidth: 250,
      flex: 1
    },
    {
      field: "delete",
      headerName: "ลบ",
      sortable: false,
      maxWidth: 100,
      flex: 1,
      disableClickEventBubbling: true,
      renderCell: (cellValues) => {
        return <IconButton
          color="secondary"
          aria-label="add an alarm"
          sx={{color:'#76BC43'}}
          onClick={(event) => {
            handleDeleteClick(event, cellValues);
          }} >
          <DeleteIcon />
        </IconButton>
      }
    }
  ]

  const handleAlertButton = () => {
    if (alert_mode === "add") {
      if(selected_app_list === 0) {
        return
      }
      let new_app_list = []
      let selectedOption = all_app_list.find(item => item.id === selected_app_list);
      let selectedOptionName = selectedOption ? selectedOption.name : '';
      let duplicate = app_list.some(item => item.id === selected_app_list)
      if (!duplicate) {
        setAppList([])
        new_app_list.push(...app_list);
        let index = new_app_list.length;
        let org_id = location.pathname.split('/')[3]
       
        new_app_list.push({
          "id": selected_app_list,
          "org_id": org_id,
          "index": index + 1,
          "app_name": selectedOptionName

        })
        setAppList(new_app_list);
        setOpenAlert(false);
      } else {
        alert("application ซ้ำโปรดเลือกใหม่อีกครั้ง")
      }
    } else {
      let app_id = selected_app_id
      let new_list = app_list;
      new_list = new_list.filter(item => item.id !== app_id);
      new_list.sort((a, b) => a.index - b.index);
      setAppList(new_list);
      setOpenAlert(false);
    }
  }

  const handleClose = () => {
    setOpenAlert(false);
  }

  const handleBackButton = () => window.location.href = "/org/list";;

  useEffect(() => {

    if (mode === "edit") {
      createAppList()
      setButtonTitle("แก้ไขหน่วยงาน")
      let org_id = location.pathname.split('/')[3]
      getService('organizations/get_detail/' + org_id)
        .then(res => {
          console.log('res', res.data.data)
          setData(res.data.data)
          setBusy(false)
        })
    } else {
      setButtonTitle("เพิ่มหน่วยงาน")
    }

  }, []); // Or [] if effect doesn't need props or state

  const handleDeleteClick = (event, cellValues) => {
    event.preventDefault()
    let app_id = cellValues.row.id
    console.log(app_id);
    let app_name = cellValues.row.app_name
    let header = "ลบข้อมูล User Group"
    let body = "คุณต้องการลบช้อมูล "
    body += "กลุ่มผู้ใช้งาน " + app_name
    setAlertHeader(header)
    setAlertBody(body)
    setSelectedAppID(app_id)
    setAlertMode("delete")
    setOpenAlert(true)
  }

  const createAppList = () => {
    let org_id = location.pathname.split('/')[3]
    orgDetail('organizations/app/get_app', { org_id: org_id })
      .then(res => {
        let temp_app_list = []
        let org_app_detail = res.data.data;
        org_app_detail.map((item, index) => {
          temp_app_list.push({
            "id": item.app_id,
            "index": index + 1,
            "org_id": item.org_id,
            "app_name": item.app_name
          })
        })
        console.log(temp_app_list);
        setAppList(temp_app_list);
      }).catch(error => {
        console.log(error.response);
      })
  }

  const handleAddClick = (event) => {
    event.preventDefault();
    callService('applications')
      .then(res => {
        let detail_list = [];
        let {data,total} = res.data.data;
        console.log(data);
        data.map((item, index) => {
          detail_list.push({
            "id": item.app_id,
            "name": item.app_name,
            "index": index + 1
          })
        })
        console.log(detail_list);
        setAllAppList(detail_list);
      }).catch(error => {
        console.log(error.response)
      })
    setAlertHeader("เพิ่มแอพพลิเคชั่น");
    setAlertMode("add")
    setOpenAlert(true);
  }

  const handleSaveButton = () => {
    if (data.org_name === "") {
      alert("คุณยังไม่ระบุหน่วยงาน \n กรุณาเพิ่มหน่วยงาน")
    } else if (data.org_code === "") {
      alert("คุณยังไม่ระบุรหัสหน่วยงาน \n กรุณาเพิ่มรหัสหน่วยงาน")
    } else {
      if (mode === "add") {
        let object = {
          "org_code": data.org_code,
          "org_name": data.org_name,
          // "org_image": data.org_image,
          // "org_logo": data.org_logo,
        }
        callOrg('organizations/create', object)
          .then(res => {

            alert("เพิ่มหน่วยงานสำเร็จ")
            window.location.href = "/org/list";
          })
          .catch(error => {
            console.log(error.response);
            if (error.response) {
              if (error.response.status === 401) {
                window.location.href = "/auth/login";
              }
            }
          })
      } else {
        let org_id = location.pathname.split('/')[3]
        let object = {
          "org_code": data.org_code,
          "org_name": data.org_name,
          "org_image": data.org_image,
          "org_logo": data.org_logo,
        }
        let new_list = app_list.map(item => item.id);

        editOrg('organizations/update/' + data.org_id, object)
          .then(res => {
            let obj = {
              "org_id": org_id,
              "list_app_id": new_list
            }
            console.log(obj);
            callService('organizations/app/adjust', obj)
              .then(res => {
                alert("แก้ไขหน่วยงานสำเร็จ")
                window.location.href = "/org/list";
              }).catch(error => {
                console.log(error.response)
              })
          })
          .catch(error => {
            console.log(error.response);
            if (error.response) {
              if (error.response.status === 401) {
                window.location.href = "/auth/login";
              }
            }
          })
      }
    }
  }

  if (isBusy) {
    return <div>Loading...</div>;
  } else {
    return (
      <Card style={{ marginTop: '1.5rem', marginRight: '10%', marginLeft: '10%' }}>
        <CardContent>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <TextField
                fullWidth
                id="outlined-org_code"
                label="รหัสหน่วยงาน"
                variant="outlined"
                value={data.org_code}
                onChange={event => setData({ ...data, 'org_code': event.target.value })}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                fullWidth
                id="outlined-org_name"
                label="ชื่อหน่วยงาน"
                variant="outlined"
                value={data.org_name}
                onChange={event => setData({ ...data, 'org_name': event.target.value })}
              />
            </Grid>
          </Grid>
        </CardContent>
        <CardActions>
          <Grid
            container
            spacing={2}
            direction="row"
            justifyContent="flex-end"
            alignItems="center"
          >
            <Grid item>
              <Button
                variant="contained"
                color="success"
                onClick={handleSaveButton}
              >
                {button_title}
              </Button>
            </Grid>
            <Grid item>
              <Button
                variant="contained"
                color="error"
                onClick={handleBackButton}
              >
                ย้อนกลับ
              </Button>
            </Grid>
          </Grid>
        </CardActions>
        {
          mode == "add" ? null :
            <CardActions>
              <Grid item >
                <Button
                  variant="contained"
                  onClick={handleAddClick}
                  sx={{
                    backgroundColor: '#76BC43',
                    '&:hover': {
                      backgroundColor: '#5e9e36',
                    },
                  }}
                >
                  เพิ่มแอปพลิเคชัน
                </Button>

              </Grid>
            </CardActions>

        }
        {
          mode == "add" ? null :
            <CardActions>
              <Grid item xs={12} >
                <div style={{ height: 400, width: '100%', marginTop: '2rem' }}>
                  <DataGrid
                    style={{ backgroundColor: "#FFFFFF" }}
                    rows={app_list}
                    columns={columns}
                    rowsPerPageOptions={[]}
                  />
                </div>
              </Grid>
              <Dialog
                open={open_alert}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
              >
                <DialogTitle id="alert-dialog-title">
                  {alert_header}
                </DialogTitle>
                <DialogContent>
                  {alert_mode === "delete" && (
                    <DialogContentText id="alert-dialog-description">
                      {alert_body}
                    </DialogContentText>
                  )
                  }
                  {alert_mode === "add" && (
                    <Select
                      labelid="demo-simple-select-label"
                      id="demo-simple-select"
                      value={selected_app_list}
                      label="หน่วยงาน"
                      fullWidth
                      onChange={event => setSelectedAppList(event.target.value)}
                    >
                      <MenuItem key={0} value={0}><em>กรุณาเลือกแอปพลิเคชัน</em></MenuItem>
                      {all_app_list.map((item, index) => {
                        return (<MenuItem key={index + 1} value={item.id}>{item.name}</MenuItem>)
                      })}
                    </Select>

                  )}
                </DialogContent>
                <DialogActions>
                  <Button onClick={handleAlertButton} variant="contained" color="success">
                    ตกลง
                  </Button>
                  <Button onClick={handleClose} variant="contained" color="error">
                    ยกเลิก
                  </Button>
                </DialogActions>
              </Dialog>
            </CardActions>

        }
      </Card>
    )
  }

}
export default OrgForm;