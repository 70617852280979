import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import reportWebVitals from './reportWebVitals';
import App from './App';
import './App.css'
import THEME from './theme'
// import { MuiThemeProvider } from '@material-ui/core/styles';
import { ThemeProvider } from '@mui/material/styles';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(

    <App />

);

reportWebVitals();

