import * as React from 'react';
import { Button, CssBaseline, TextField, Box, Typography, Container, Alert } from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { adminLogin } from '../../services/admin/Auth';
import LocalStorage from '../../utils/LocalStorage';
import logo from '../../rice_logo.png';
import backgroundImage from '../../bg_login.jpg'; // Update the path accordingly
export default function SignIn() {
  const [username, setUsername] = React.useState('');
  const [password, setPassword] = React.useState('');
  const [isSuccess, setIsSuccess] = React.useState(true);
  const [errorMessage, setErrorMessage] = React.useState('');

  const handleSubmit = (event) => {
    event.preventDefault();
    const credentials = {
      username,
      password,
      app_id: 1,
      org_id: 0,
    };

    adminLogin(credentials)
      .then((res) => {
        const { accessToken, refreshToken } = res.data.data[0];
        LocalStorage.setAccessToken(accessToken);
        LocalStorage.setRefreshToken(refreshToken);
        setIsSuccess(true);
        window.location.href = '/org/list';
      })
      .catch((error) => {
        if (error.response && error.response.status === 401) {
          setIsSuccess(false);
          setErrorMessage('ไม่พบชื่อผู้ใช้งานในระบบ');
        }
      });
  };

  return (
      <Container
        component="main"
        maxWidth="3xl"
        sx={{
          height: '100vh',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          position: 'relative',
        }}
      >
        <CssBaseline />
        <Box
          sx={{
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            backgroundImage: `url(${backgroundImage})`,
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            filter: 'blur(4px)',
            zIndex: -1,
          }}
        />
        <Box
          sx={{
            backgroundColor: '#ffffe6',
            padding: 4,
            borderRadius: 2,
            boxShadow: 3,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            zIndex: 1,
          }}
        >
          <img style={{ width: '200px' }} src={logo} alt='Rice Department Logo' />
          <Typography align='center' component="h1" variant="h5" sx={{ mb: 2 }}>
            ระบบจัดการข้อมูลผู้ใช้งานกรมการข้าว
          </Typography>
          <Box component="form" onSubmit={handleSubmit} noValidate>
            {!isSuccess && <Alert severity="error" sx={{ mb: 2 }}>{errorMessage}</Alert>}
            <TextField
              margin="normal"
              required
              fullWidth
              id="username"
              label="ชื่อบัญชีผู้ใช้"
              name="username"
              autoComplete="username"
              sx={{backgroundColor:'white'}}
              autoFocus
              onChange={(e) => setUsername(e.target.value)}
            />
            <TextField
              margin="normal"
              required
              fullWidth
              name="password"
              label="รหัสผ่าน"
              type="password"
              id="password"
              sx={{backgroundColor:'white'}}
              autoComplete="current-password"
              onChange={(e) => setPassword(e.target.value)}
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
            >
              เข้าสู่ระบบ
            </Button>
          </Box>
          <Typography variant="body2" align="center" sx={{ mt: 2 }}>
            เวอร์ชัน 2.0 
          </Typography>
          <Typography variant='body2' align='center'>
          โดย ศูนย์เทคโนโลยีสารสนเทศและการสื่อสาร กรมการข้าว
          </Typography>
        </Box>
      </Container>
  );
}
