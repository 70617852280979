import React, { useState, useEffect } from 'react';
import AdminLayout from '../../components/admin/AdminLayout'
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import NewsForm from '../../components/NewsForm';


export default function NewsEdit() {
  return (
    <div style={{ margin: '2rem' }}>
      <AdminLayout />
      <Grid container style={{ marginTop: '4rem', marginBottom: '2rem' }}>
        <Grid item xs={12}>
          <Typography variant='h4' style={{ fontWeight: 'bold' }}>แก้ไขข่าวสาร</Typography>
        </Grid>
        <Grid item xs={12} >
          <NewsForm mode='edit' />
        </Grid>
      </Grid>
    </div>
  )
}